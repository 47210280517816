<template>
  <div>
    <div>
      <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Đội ngũ vận hành</h1>
    </div>
    <div v-for="(role,index) in roles" :key="'role' + index">
      <div class="sm:my-6 my-3 flex items-center justify-between border-b pb-3 relative">
        <div>
          <router-link :to="{name: 'RoleDetail', params: {slug: role.slug}}">
            <h2 class="text-xl font-semibold">{{ role.name }} ({{ role.users.paginatorInfo.total }} thành viên)</h2>
          </router-link>
        </div>
      </div>

      <div class="relative">
        <div class="uk-slider-container">
          <ul class="uk-child-width-1-5@m uk-child-width-1-5@s uk-child-width-1-2 uk-grid-small uk-grid"
              v-if="role.users && role.users.data && role.users.data.length">
            <li v-for="(user, index) in role.users.data" :key="'user' + index" class="mb-4">
              <div class="card">
                <router-link
                    :to="{name: 'UserDetail', params: {id: user.id}}">
                  <img :alt="user.username"
                       :src="user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'"
                       class="h-44 object-cover rounded-t-md shadow-sm w-full">
                </router-link>
                <div class="p-3">
                  <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
                    <router-link
                        :to="{name: 'UserDetail', params: {id: user.id}}">
                      {{ user.username }}
                    </router-link>
                  </h4>
                  <p class="font-medium text-sm people-in-song line-clamp-1">
                    {{ getCustomTitle(user.userRolePivot, role) }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {changePageTitle, getCustomTitle} from "../../core/services/utils.service";

export default {
  name: "RoleIndex",
  data() {
    return {
      roles: []
    }
  },
  methods: {
    loadRoles() {
      let where = {
        AND: [
          {
            column: "is_operating",
            value: 1
          }
        ]
      };
      
      let query = `query($page: Int, $where: WhereConditions) {
        roles(first: 10, page: $page, where: $where) {
          data {
            id
            name
            slug
            included_name
            group_type
            users(first: 100) {
              data {
                id
                username
                avatar {
                  url
                }
                userRolePivot {
                  role
                  custom_title
                }
              }
              paginatorInfo {
                total
                currentPage
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {page: 1, where: where})
          .then(({data}) => {
            if (data.data && data.data.roles && data.data.roles.data && data.data.roles.data.length) {
              this.roles = data.data.roles.data;
              changePageTitle("Đội ngũ vận hành");
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    getCustomTitle(pivot, role) {
      return getCustomTitle(pivot, role);
    }
  },
  mounted() {
    this.loadRoles();
  }
}
</script>
